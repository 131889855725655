<!--领取教研室成员卡-- 刘伟成 --7.9日，-->
<template>
  <div class="cardpwd flex flex-column align-items-center">
    <div class="card-content flex flex-column align-items-center">
      <div class="card-content-header">
        <img src="../assets/flowerY.png" alt />
        <span v-if="isGet">您已经是教研室成员啦！</span>
        <span v-else>欢迎您加入教研室!</span>
        <p v-if="!isGet">以下是您的卡片信息，记得保存哦～</p>
      </div>

      <div class="themeLogo">
        <img src="../assets/memberCard.png" alt />
      </div>
      <ul v-if="isGet" class="msg">
        <li>进入向知APP，了解更多🤎</li>
      </ul>
      <ul v-else class="msg">
        <li>
          <span>账号：{{ userData.account }}</span>
        </li>
        <li>
          <span>密码：{{ userData.pwd }}</span>
        </li>
      </ul>
    </div>
    <div class="down">
      <div class="AppDetail">
        <img src="../assets/icon_logo.png" alt />
        <ul class="AppTheme">
          <li class="appName">向知APP</li>
          <li class="appDes">向光而生，向知而行</li>
        </ul>
      </div>
      <span class="button" @click="download">下载</span>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      userData: {
        account: "",
        pwd: "",
      },
      isGet: "",
    };
  },
  components: {},
  methods: {
    download() {
      const u = navigator.userAgent;
      const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isAndroid) {
        // window.location.href = 'https://tiantiantongshi.com/openapp'
        window.location.href = "xz://openapp/welcome";
        setTimeout(() => {
          const hidden =
            window.document.hidden ||
            window.document.mozHidden ||
            window.document.msHidden ||
            window.document.webkitHidden;
          if (typeof hidden === "undefined" || hidden === false) {
            // 应用宝下载地址
            window.location.href = "https://web.lumibayedu.com/api/config/apk";
          }
        }, 100);
      } else if (isiOS) {
        // window.location.href = 'https://h5.lumibayedu.com/openapp'
        // window.location.href = "xzlumibay://";
        this.downloader = setTimeout(() => {
          const hidden =
            window.document.hidden ||
            window.document.mozHidden ||
            window.document.msHidden ||
            window.document.webkitHidden;
          if (typeof hidden === "undefined" || hidden === false) {
            // 应用宝下载地址
            window.location.href =
              "https://itunes.apple.com/cn/app/id1547402998";
          }
        }, 100);
      }
    },
  },
  created() {
    this.isGet = this.$route.query.isGet;
    this.userData = this.$route.query;
    //请求渲染数据
  },
  beforeMount() {},
};
</script>

<style>
.icon-guanbi {
  font-size: 12px;
}
.cardpwd {
  width: 100vw;
  min-height: 812px;
  height: 100vh;
  background-color: #1c362b;
}
.cardpwd > .card-content {
  margin-top: 80px;
  width: 100vw;
  height: 510px;
}
.cardpwd > .card-content > .card-content-header {
  margin-top: 27px;
  height: 120px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}
.cardpwd > .card-content > .card-content-header > img {
  margin-right: 12px;
  vertical-align: middle;
  width: 36px;
  height: 36px;
}

.cardpwd > .card-content > .card-content-header > p {
  margin-top: 40px;
  font-size: 13px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #bcbcbc;
  line-height: 18px;
}

.cardpwd > .card-content > .themeLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 280px;
  background-image: url(../assets/getMember/bgcround.png);
  background-size: 100%;
}
.cardpwd > .card-content > .themeLogo > img {
  width: 310px;
  height: 160px;
  border-radius: 4px;
}
.cardpwd > .card-content > .msg {
  margin-top: 41px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.cardpwd > .down {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.22rem 0.44rem;
  background: #f8f8f8;
  width: 100vw;
  height: 7.8125rem;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
}
.cardpwd > .down > .AppDetail > img {
  width: 2.625rem;
  height: 2.625rem;
  vertical-align: top;
}
.cardpwd > .down > .AppDetail .AppTheme {
  display: inline-block;
  padding-left: 10px;
}
.cardpwd > .down > .AppDetail > .AppTheme > .appDes {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.cardpwd > .down > .AppDetail > .AppTheme .appName {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.cardpwd > .down > .button {
  display: inline-block;
  padding: 0.4375rem 2.0625rem;
  width: 6.25rem;
  height: 2.125rem;
  background: #1e4b4b;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
</style>